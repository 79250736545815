.icon {
  display: inline-block;
  background: center center no-repeat;
}

.icon-photo {
  width: 27px;
  height: 22px;
  background-image: url('../images/icons/icon-photo-temp@2x.png');
  background-size: contain;
}

.icon-video {
  width: 27px;
  height: 27px;
  background-image: url('../images/icons/icon-video-temp@2x.png');
  background-size: contain;
}

.icon-play {
  width: 87px;
  height: 87px;
  @include background-image-retina(
    '../images/icons/icon-play',
    'png',
    87px,
    87px
  );
}

.icon-archive {
  width: 23px;
  height: 42px;
  @include background-image-retina(
    '../images/icons/icon-archive',
    'png',
    23px,
    42px
  );
}

.icon-search {
  @include icon('../images/icons/search.png', 1.5rem, 1.5rem);
}

.icon-vk {
  @include icon('../images/icons/vk.png', 3.75rem, 3.75rem);
}

.icon-fb {
  @include icon('../images/icons/fb.png', 3.75rem, 3.75rem);
}
.icon-in {
  @include icon('../images/icons/in.png', 3.75rem, 3.75rem);
}

.icon-burger {
  @include icon('../images/icons/burger.png', 44px, 23px);
}

.icon-close {
  @include icon('../images/icons/close.png', 35px, 35px);
}

.icon-in-mobile {
  @include icon('../images/icons/instagram.png', 37px, 37px);
}

.icon-fb-mobile {
  @include icon('../images/icons/facebook.png', 37px, 37px);
}

.icon-vk-mobile {
  @include icon('../images/icons/vkmobile.png', 37px, 37px);
}

.icon-vk-modal {
  @include icon('../images/icons/vkmodal.png', 24px, 25px);
}

.icon-fb-modal {
  @include icon('../images/icons/fbmodal.png', 24px, 25px);
}

.icon-wp-modal {
  @include icon('../images/icons/wpmodal.png', 24px, 25px);
}

.icon-download-modal {
  @include icon('../images/icons/download.png', 24px, 25px);
}

.icon-tw-modal {
  @include icon('../images/icons/twmodal.png', 24px, 25px);
}

.icon-tel-modal {
  @include icon('../images/icons/telmodal.png', 24px, 25px);
}

.icon-article-left {
  @include icon('../images/icons/articleico-l.png', 7px, 12px);
}

.icon-article-right {
  @include icon('../images/icons/articleico-r.png', 7px, 12px);
}

.social {
  display: flex;
  &.__photoreview {
    @include mq($until: mobile) {
      display: none;
    }
    &.__mobile {
      display: none;
      @include mq($until: mobile) {
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.social-icon-tl {
  @include icon('../images/icons/social-tl.png', 2.5rem, 2.5rem);
}

.social-icon-wp {
  @include icon('../images/icons/social-wp.png', 2.5rem, 2.5rem);
}

.social-icon-tw {
  @include icon('../images/icons/social-tw.png', 2.5rem, 2.5rem);
}

.social-icon-vk {
  @include icon('../images/icons/social-vk.png', 2.5rem, 2.5rem);
}

.social-icon-fb {
  @include icon('../images/icons/social-fb.png', 2.5rem, 2.5rem);
}

.social-icon {
  margin-right: .6rem;
  transition: opacity .3s ease;

  &:hover {
    opacity: .7;
  }
  @include mq($until: desktop) {
    width: 2rem !important;
    height: 2rem !important;
  }
  @include mq($until: desktopAd) {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}
