.intro{
	padding: 13rem 0 12rem;
  @include mq($until: tablet) {
    padding: 0 0 3rem;
  }
}

.intro-title{
	margin-bottom: 2rem;
  font-size: 6.4rem;
  line-height: 1.23em;
  font-weight: 600;
  @include mq($until: tablet) {
    font-size: 3rem;
    br{
      display: none;
    }
  }
}

.intro-text{
	margin-bottom: 3rem;
	color: rgb(143, 143, 143);
  font-size: 2.4rem;
  line-height: 1.5em;
  animation-delay: .3s;
  @include mq($until: tablet) {
    font-size: 2rem;
    br{
      display: none;
    }
  }  
}

.intro-link{
	padding: 2rem 5rem;
	color: rgb(214, 33, 9);
  border: 2px solid rgb(0, 0, 0);
	display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  animation-delay: .6s;
  &:hover{
    text-decoration: none;
    opacity: .7;
  }
}