.contacts-form{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: black;
	height: 100vh;
  @include mq($until: tablet) {
    padding: 3rem 0;
    height: auto;
  }	
}

.contacts-form-title{
	margin-bottom: 4rem;
	font-size: 4.2rem;
  line-height: 1.23em;
  color: white;
  text-align: center;
  font-weight: 600;
}

.contacts-form-desc{
	margin-bottom: 5rem;
	font-size: 2rem;
  line-height: 1.55em;
  color: white;
  text-align: center;
  animation-delay: .3s;
}

.contacts-form-input{
 	margin-bottom: .5rem;
	width: 100%;
  color: #000000;
  background-color: white;
  border-radius: 7px;
  border: 0;
	padding: 2rem;
	font-size: 1.6rem;
  line-height: 1.33em;
}

.contact-form-message{
  margin-bottom: .5rem;
	width: 100%;
  color: #000000;
  background-color: white;
  border-radius: 7px;
  border: 0;
	padding: 2rem;
	font-size: 1.6rem;
  line-height: 1.33em;
}

.contacts-form-row{
	margin-bottom: 2rem;
	&.__center{
		text-align: center;
	}
}

.contacts-form-inner{
	animation-delay: .6s;
}

.contacts-form-btn{
	color: white;
	font-size: 1.6rem;
	font-weight: 600;
	border: 0;
	background: transparent;
	cursor: pointer;
	&:hover,
	&focus,
	&:active{
		outline: 0;
		color: red;
	}
}

.contacts-form-alert{
  display: none;
  color: red;
  font-size: 1.6rem;	
}

.contacts-form-alert-title{
	display: none;
  background: #F95D51;
  padding: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.6rem;		
}

.contacts-form-success-title{
	display: none;
  background: rgb(5, 152, 32);
  padding: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: white;
  font-size: 1.6rem;		
}