.since{
	height: 100vh;
	background: url(../images/photo.jpg);
  background-size: cover;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  position: relative;
  background-attachment: fixed;
  overflow: hidden;
  @include mq($until: tablet) {
    height: auto;
  }   	
}

.since-bg{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%,rgba(0, 0, 0, .7) 100%);
  @include mq($until: tablet) {
    padding: 3rem 0;
  }    
}

.since-title{
	color: white;
  margin-bottom: 5rem;
  font-size: 5.2rem;
  line-height: 1.23em;
  font-weight: 600;
  &:after{
  	content: '';
  	margin: 5rem auto 0;
  	width: 16rem;
  	height: 1px;
  	display: block;
  	background: white;
  }
  @include mq($until: tablet) {
    font-size: 3rem;
    br{
      display: none;
    }
  }  
}

.since-date{
  color: white;
  font-size: 2.2rem;
  line-height: 1.55em;
}