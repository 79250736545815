@mixin clearfix {
  *zoom: 1;

  &:after,
  &:before {
    display: table;
    content: ' ';
  }

  &:after {
    clear: both;
  }
}
@mixin icon($url, $sizew, $sizeh) {
  width: $sizew;
  height: $sizeh;
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file+'.'+$type);

  @media (min-resolution: 2), (min-resolution: 144dpi) {
    & {
      background-image: url($file+'@2x.'+$type);
      background-size: $width $height;
    }
  }
}
