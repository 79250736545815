header{
	top: 0;
	left: 0;
	padding: 2rem;
	position: fixed;
	width: 100%;
	z-index: 10;
	@include mq($until: tablet) {
		margin-bottom: 3rem;
   	position: relative;
  }
}