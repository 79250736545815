footer{
	padding-top: 7.5rem;
  padding-bottom: 21rem;
	background: black;
}

.footer-text{
	text-align: center;
  color: #9d9d9d;
  font-size: 1.6rem;
  line-height: 1.2em;
  a{
  	color: #9d9d9d;
  }
}