* {
  box-sizing: border-box;
}

html{
  font-size: 10px;
}

body {
  font-family: $ff-roboto;
  &._active {
    overflow: hidden;
    height: 100%;
  }
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  @include mq($until: wide) {
    max-width: 110rem;
  }
  @include mq($until: desktop) {
    max-width: 90rem;
  }
  @include mq($until: desktopAd) {
    max-width: 90%;
  }
}

.row {
  max-width: 64rem;
  margin: 0 auto;
}

.hidden {
  display: none !important;
}

.ico {
  display: inline-block;
}
